<template>
    <v-main class="grey lighten-4">
        <v-card tile class="pa-3 ma-10">
            <v-card-title>
                System Version Logs
            </v-card-title>
            <v-simple-table>
                <tbody>
                    <tr>
                        <th>Version</th>
                        <th>Date&Time</th>
                        <th>Details</th>
                    </tr>
                    <tr v-for="log in logs" :key="log.version">
                        <td>{{ log.version }}</td>
                        <td>{{ log.date }}</td>
                        <td v-html="log.message"></td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-main>
</template>

<script>
import { logs } from '@/lib/version'

export default {
    data: () => ({
        logs
    })
}
</script>
