var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-card",
        { staticClass: "pa-3 ma-10", attrs: { tile: "" } },
        [
          _c("v-card-title", [_vm._v(" System Version Logs ")]),
          _c("v-simple-table", [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("th", [_vm._v("Version")]),
                  _c("th", [_vm._v("Date&Time")]),
                  _c("th", [_vm._v("Details")]),
                ]),
                _vm._l(_vm.logs, function (log) {
                  return _c("tr", { key: log.version }, [
                    _c("td", [_vm._v(_vm._s(log.version))]),
                    _c("td", [_vm._v(_vm._s(log.date))]),
                    _c("td", { domProps: { innerHTML: _vm._s(log.message) } }),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }