export const logs = [
    {
        version: '0.3.28',
        date: '2022/6/21 18:15',
        message:
            `
            [New features]
            <ul>
                <li>Added some features for Audio Segmentation template preset</li>
                <li>
                    <ul>
                        <li>Improved waveform visualization</li>
                        <li>Improved interface for changing segment time</li>
                        <li>Drag-and-move timeline</li>
                        <li>Edit label segments by hitting Enter key</li>
                    </ul>
                </li>
            </ul>
            `
    },
    {
        version: '0.3.27',
        date: '2022/6/20 15:35',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed an issue that mounted hook of the first template double-called</li>
            </ul>
            `
    },
    {
        version: '0.3.26',
        date: '2022/6/16 0:40',
        message:
            `
            [New features]
            <ul>
                <li>Added Audio Segmentation template preset</li>
            </ul>
            `
    },
    {
        version: '0.3.25',
        date: '2022/6/13 18:40',
        message:
            `
            [New features]
            <ul>
                <li>Response table now displays platform name and platform worker ID</li>
            </ul>
            `
    },
    {
        version: '0.3.24',
        date: '2022/6/13 18:10',
        message:
            `
            [New features]
            <ul>
                <li>Template and template preset renderer shows nano props and nano answers in the right navigation bar</li>
            </ul>
            `
    },
    {
        version: '0.3.23',
        date: '2022/6/13 3:55',
        message:
            `
            [New features]
            <ul>
                <li>Users can change their password</li>
            </ul>
            `
    },
    {
        version: '0.3.22',
        date: '2022/6/12 23:15',
        message:
            `
            [New features]
            <ul>
                <li>File Storage is now available</li>
                <li>Added Video Timeline Segment Labeling preset</li>
            </ul>
            `
    },
    {
        version: '0.3.21',
        date: '2022/6/9 14:30',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed data loading issue in response table</li>
            </ul>
            [New features]
            <ul>
                <li>Creating templates from preset catalog is now available</li>
                <li>Response table can now be grouped by a column</li>
                <li>Large answer value object is skipped and displayed in a dialog</li>
            </ul>
            `
    },
    {
        version: '0.3.20',
        date: '2022/5/23 22:35',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed setting response function in workplace</li>
            </ul>
            `
    },
    {
        version: '0.3.19',
        date: '2022/5/6 18:35',
        message:
            `
            [Bug fix]
            <ul>
                <li>Now managing HIT batches individually per credential</li>
            </ul>
            [New features]
            <ul>
                <li>Added account add/delete menus</li>
            </ul>
            `
    },
    {
        version: '0.3.18',
        date: '2022/3/10 18:10',
        message:
            `
            [New features]
            <ul>
                <li>Response watcher now returns from past records</li>
            </ul>
            `
    },
    {
        version: '0.3.17',
        date: '2022/3/4 0:25',
        message:
            `
            [New features]
            <ul>
                <li>Project scheme editor is now available</li>
                <li>Changed page toolbars for template and scheme pages</li>
            </ul>
            `
    },
    {
        version: '0.3.16',
        date: '2022/3/3 19:15',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed a problem that ESLint error overlay prevented further edit in template editor</li>
            </ul>
            `
    },
    {
        version: '0.3.15',
        date: '2022/3/3 18:40',
        message:
            `
            [New features]
            <ul>
                <li>Implemented template code editor</li>
            </ul>
            `
    },
    {
        version: '0.3.14',
        date: '2022/3/2 15:20',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed internal errors in deleting some metadata in deleting nanotasks</li>
            </ul>
            `
    },
    {
        version: '0.3.13',
        date: '2022/3/1 13:50',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed internal errors in deleting some metadata in deleting nanotasks</li>
                <li>Handling and showing errors in MTurk sign-in</li>
            </ul>
            `
    },
    {
        version: '0.3.12',
        date: '2022/2/28 11:05',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed internal errors in deleting some metadata for Tutti resources</li>
                <li>Alert dialog in deleting MTurk credentials now displays messages</li>
            </ul>
            `
    },
    {
        version: '0.3.11',
        date: '2022/2/16 14:55',
        message:
            `
            [New feature]
            <ul>
                <li>Added some frontend features for Tutti Market's Job Classes</li>
            </ul>
            `
    },
    {
        version: '0.3.10',
        date: '2022/2/16 02:35',
        message:
            `
            [Bug fix]
            <ul>
                <li>Backend fix to work session-relevant event</li>
                <li>Frontend fix to Tutti market's job terminator page</li>
                <li>Frontend fix to work place appearance</li>
            </ul>
            `
    },
    {
        version: '0.3.9',
        date: '2022/2/15 23:00',
        message:
            `
            [New features]
            <ul>
                <li>
                    Backend implementations for:
                    <ul>
                        <li>Response watcher methods</li>
                        <li>Automation/Platform parameter sets</li>
                        <li>Nanotask groups</li>
                    </ul>
                </li>
            </ul>
            `
    },
    {
        version: '0.3.8',
        date: '2022/2/14 22:10',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed glitches for MTurk HIT Creation: adding HITs to existing batch & "Copy To New" button</li>
            </ul>
            `
    },
    {
        version: '0.3.7',
        date: '2022/2/14 11:10',
        message:
            `
            [Bug fix]
            <ul>
                <li>Renewed client API version so signing up does not fail</li>
            </ul>
            [New features]
            <ul>
                <li>Vuetify_Video template preset has been added</li>
            </ul>
            `
    },
    {
        version: '0.3.6',
        date: '2022/2/3 23:15',
        message:
            `
            [Bug fix]
            <ul>
                <li>Resolved some input type bugs that prevented from creating MTurk HITs</li>
            </ul>
            [New features]
            <ul>
                <li>Tooltips are shown for menu bar icons when folded</li>
                <li>Refresh button in MTurk HITs list page</li>
                <li>Responses can be downloaded as files (JSON & CSV; kinda experimental)</li>
            </ul>
            `
    },
    {
        version: '0.3.5',
        date: '2022/2/2 0:15',
        message:
            `
            [Bug fix]
            <ul>
                <li>TemplateNode works fine in tasks when merged_props parameter is unspecified</li>
                <li>Some old template presets are now working fine</li>
            </ul>
            [New features]
            <ul>
                <li>.env now accepts registering allowed CORS origins (mainly for API client accesses)</li>
            </ul>
            [Engine improvement]
            <ul>
                <li>Faster build by caching Docker image data</li>
            </ul>
            `
    },
    {
        version: '0.3.4',
        date: '2022/1/26 15:50',
        message:
            `
            [Bug fix]
            <ul>
                <li>Fixed bug that double-counted WorkerContext.project_id counter</li>
            </ul>
            `
    },
    {
        version: '0.3.3',
        date: '2022/1/25 19:30',
        message:
            `
            [New features]
            <ul>
                <li>Created reload button in MTurk's Assignments list page</li>
            </ul>
            `
    },
    {
        version: '0.3.2',
        date: '2022/1/25 17:30',
        message:
            `
            [Bug fix]
            <ul>
                <li>MTurk's ApproveAssignments now accepts OverrideRejection parameter</li>
            </ul>
            `
    },
    {
        version: '0.3.1',
        date: '2022/1/25 11:30',
        message:
            `
            [New features]
            <ul>
                <li>getProjectScheme operation now requires authentication</li>
            </ul>
            `
    },
    {
        version: '0.3.0',
        date: '2022/1/15 19:00',
        message:
            `
            <ul>
                <li>Started recording version logs</li>
            </ul>
            `
    },
];

export const currentVersion = logs[0].version;
